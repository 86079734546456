.ie {

  .service {
    .hns-section {
      .inner {
        background-size: 57% auto;
      }
    }
  }

  .support {
    .form-container {
      .borderArea {
        height: 109px;
      }
    }
  }
}