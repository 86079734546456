@import 'base', 'base/typography';

.intro {
  //height: 525px;
  height: 660px;
  position: relative;
  padding-top: 135px;

  @include mobile {
    &::before {
      content: '';
      position: absolute;
      height: 40px;
      background-color: $yellow;
      top: 95px;
      width: 100%;
    }
  }

  @include tablet {
    height: 700px;
    padding-top: 0;
    background-color: transparent;
  }

  @include iphone-x-landscape {
    //height: 585px;
    height: 720px;
  }

  @include desktop-large {
    height: 725px;
  }

  .hns-section {
    height: 364px;
    position: relative;
    background-color: $yellow;
    

    @include tablet {
      height: 464px;
    }

    @include iphone-x-landscape {
      height: 404px;
    }

    @include desktop {
      height: 464px;
      @include decorations(210px, 70% auto);
    }

    &::before {
      content: '';
      background-image: url("../images/flyingGuy.svg");
      height: 269px;
      width: 350px;
      position: absolute;
      left: calc(50% - 200px);
      bottom: -140px;

      @include mobile-landscape {
        height: 346px;
        width: 450px;
        left: calc(50% - 275px);
        bottom: -160px;
      }

      @include mobile-plus-landscape {
        height: 384px;
        width: 500px;
        left: calc(50% - 305px);
        bottom: -180px;
      }

      @include tablet {
        height: 526px;
        width: 685px;
        left: calc(50% - 442px);
        bottom: -290px;
      }

      @include iphone-x-landscape {
        height: 346px;
        width: 450px;
        left: calc(50% - 275px);
        bottom: -160px;
      }

      @include desktop {
        height: 526px;
        width: 685px;
        left: calc(50% - 442px);
        bottom: -290px;
      }
    }

    &::after {
      content: '';
      display: block;
      height: 100%;
      width: 120%;
      position: absolute;
      left: 50%;
      bottom: 0;
      @include divider;
      background-color: $yellow;

      @include desktop-large {
        height: 100%;
      }
    }

    .inner {
      padding: 40px 0;

      @include tablet {
        padding: 80px 0;
      }
    } 
  }
}

.pricing {
  height: 650px;

  @include mobile-landscape {
    height: 580px;
  }

  @include tablet {
    height: 648px;
  }

  @include iphone-x-landscape {
    height: 560px;
  }

  @include desktop {
    height: 648px;
  }

  @include desktop-large {
    height: 686px;
  }

  .hns-section {
    height: 480px;
    position: relative;

    @include mobile-landscape {
      height: 450px;
    }

    @include tablet {
      height: 450px;
    }

    @include iphone-x-landscape {
      height: 420px;
    }

    .inner {
      height: 480px;
      background-image: url("../images/pricing_mobile.svg");
      background-size: 93% auto;
      background-repeat: no-repeat;
      background-position-x: center;
      background-position-y: 300px;

      @include mobile-landscape {
        background-position-x: center;
        background-position-y: 200px;
        background-size: 90% auto;
      }

      @include tablet {
        height: 420px;
        background-image: url("../images/pricing.svg");
        background-size: 95% auto;
        background-position: center bottom;
      }

      @include iphone-x-landscape {
        background-position-x: center;
        background-position-y: 230px;
      }

      @include desktop {
        height: 420px;
        background-image: url("../images/pricing.svg");
        background-size: 80% auto;
        background-position: center bottom;
        margin-top: 40px;
      }

      @include desktop-large {
        height: 480px;
        margin-top: 60px;
      }

      .btn {
        margin-top: 5em;

        @include mobile-landscape {
          margin-top: 4em;
        }

        @include tablet {
          margin-top: 4em;
        }
      }
    }

    p {
      padding: 0 40px;

      @include tablet {
        padding: 0;
      }

      br {
        @include tablet {
          display: none;
        }
      }
    }

    .price {
      text-align: center;
      padding: 25px 0 0 40px;
      margin: 0;

      @include tablet {
        padding: 25px 0 0 60px;
        margin: 0 0 15px 0;
      }

      span {
        @include bold;
        font-size: 5.000em;
        text-shadow: 2px 2px 0px $yellow;
        position: relative;


        @include tablet {

        }

        @include tablet {
          font-size: 5.750em;
        }

        > span {
          font-size: 0.525em;
          position: absolute;
          top: 20px;
          left: -35px;
        }

        .just {
          top: -5px;
          left: -30px;
          position: absolute;
          
        }
    
        small {
          color: $yellow;
          @include light;
          font-size: 17px;
          text-shadow: none;

          @include tablet {
            font-size: 20px;
          }
        }
      }
    }
  }
}


.speeds {
  height: 520px;

  @include tablet {
    height: 620px;
  }

  @include desktop-large {
    height: 700px;
  }

  .hns-section {
    height: 410px;
    position: relative;
   

    @include mobile-landscape {
      height: 380px;
    }

    @include tablet {
      height: 430px;
    }

    @include desktop {
      @include decorations(90px, 70% auto);
    }

    &::before {
      @include divider;
      top: -100px;
      border-top: 2px solid $black;
      padding-top: 10px;

      @include mobile-landscape {
        top: -125px;
        height: 150px;
      }

      @include tablet {
        top: -150px;
      }

      @include desktop {
        top: -190px;
      }

      @include desktop-large {
        top: -257px;
        height: 100%
      }
    }
    
    &::after {
      @include divider;
      height: 100%!important;
      bottom: 0;
      
      @include desktop-large {
        height: 100%;
      }
    }

    .inner {
      height: 420px;
      
      @include mobile-landscape {
        margin-top: 20px;
      }
      
      &::before {
        content: '';
        background-image: url("../images/speeds.svg");
        width: 158px;
        height: 250px;
        position: absolute;
        left: calc(50% - 85px);
        bottom: -40px;

        @include mobile-landscape {
          width: 140px;
          height: 220px;
          bottom: 10px;
        }

        @include tablet {
          left: calc(50% - 93px);
          width: 187px;
          height: 295px;
        }
      }

      @include tablet {
        margin-top: 40px;
      }

      @include desktop {
        margin-top: 60px;
      }

      h5 {
        color: $black;
      }

      h2 {
        @include text-shadow($accent);
      }

      .speed {
        width: 350px;
        height: 187px;
        margin: 20px auto;
        color: $black;
        position: relative;

        @include mobile-xs {
          width: 300px;
        }

        @include tablet {
          width: 540px;
        }

        span {
          @include bold;
          font-size: 2.500em;

          @include mobile-xs {
            font-size: 2.000em;
          }

          @include tablet {
            font-size: 2.938em;
          }

          > .tilde {
            font-size: 0.7em;
            top: -6px;
            position: relative;
            @include light;
            padding-left: 5px;
          }

          > .updown {
            font-size: 0.325em;
            position: absolute;
            text-align: center;
            right: 0;
            bottom: -10px;

            @include tablet {
              width: 84px;
              font-size: 0.375em;
              bottom: -15px;
            }
          }

          &.left {
            position: absolute;
            left: 0;
            top: 0;
          }
    
          &.right {
            position: absolute;
            right: 0;
            bottom: 0;
          }
        }

        small {
          @include light;
          font-size: 0.438em;
          padding-left: 5px;

          @include tablet {
            font-size: 0.681em;
          }

          &:first-child {
            padding: 0;
          }
        }
      }
    }
  }
}

.service {
  //height: 677px;
  height: 588px;

  @include mobile-landscape {
    height: 555px;
  }

  @include mobile-xs-landscape {
    height: 500px;
  }

  @include tablet {
    height: 627px;
  }

  .hns-section {
    height: 480px;
    position: relative;

    @include mobile-landscape {
      height: 460px;
    }

    @include mobile-xs-landscape {
      height: 400px;
    }

    @include tablet {
      height: 430px;
    }

    &::before {
      @include divider;
      top: -100px;
      background-color: $yellow;

      @include mobile-landscape {
        top: -132px;
      }

      @include tablet {
        top: -180px;
      }

      @include desktop-large {
        top: -261px;
      }
    }

    &::after {
      @include divider;
      height: 100% !important;
      background-color: $yellow;
      bottom: 0;
      
      @include desktop-large {
        height: 100%;
      }
    }

    .inner {
      height: 485px;
      background-image: url("../images/service.svg");
      background-repeat: no-repeat;
      background-position-x: 40%;
      background-position-y: 270px;
      background-size: 73% auto; 
      margin-top: 10px;

      @include mobile-landscape {
        background-size: 50% auto; 
        background-position-y: 190px;
      }

      @include mobile-landscape {
        margin-top: 20px;
      }

      @include tablet {
        background-position-x: 45%;
        background-size: 50% auto; 
        background-position-y: 190px;
      }

      @include tablet-large {
        background-size: 40% auto; 
      }

      @include tablet-landscape {
        background-size: 40% auto; 
      }

      @include desktop {
        background-position-x: 45%;
        background-size: 36% auto; 
        background-position-y: 190px;
        margin-top: 60px;
      }

      h5 {
        color: $yellow;
      }

      h2 {
        @include text-shadow($yellow);
      }

      p {
        padding: 0 40px;

        @include tablet {
          padding: 0;
        }
      }
    }
  }
}

.happiness {
  height: 520px;

  @include mobile-landscape {
    height: 430px;
  }

  @include mobile-xs {
    height: 428px;
  }

  @include tablet {
    height: 530px;
  }

  @include desktop {
    height: 658px;
  }

  @include desktop-large {
    height: 700px;
  }

  .hns-section {
    height: 450px;
    position: relative;

    @include mobile-landscape {
      height: 370px;
    }

    @include tablet {
      height: 400px;
    }

    &::before {
      @include divider;
      border-top: 2px solid $black;
      background-color: $white;
      top: -100px;

      @include tablet {
        top: -190px;
      }

      @include iphone-x-landscape {
        top: -165px;
      }

      @include desktop {
        top: -190px;
      }

      @include desktop-large {
        top: -220px;
      }
    }
    
    &::after {
      @include divider;
      bottom: 0px;

      @include mobile-landscape {
        bottom: 0px;
      }

      @include tablet {
        bottom: 10px;
      }

      @include desktop-large {
        height: 100%;
      }
    }

    .inner {
      height: 440px;
      padding-top: 20px;

      @include tablet {
        height: 400px;
        padding: 0;
        margin-top: 20px;
      }

      @include desktop {
        height: 420px;
        padding: 0;
        margin-top: 60px;
      }

      @include desktop-large {
        margin-top: 100px;
      }
      
      &::before {
        content: '';
        background-image: url("../images/zen_mobile.svg");
        width: 318px;
        height: 237px;
        position: absolute;
        left: calc(50% - 159px);
        bottom: 0px;

        @include mobile-xs {
          width: 300px;
          height: 216px;
          left: calc(50% - 150px);
          bottom: 0px;
        }

        @include mobile-landscape {
          width: 400px;
          height: 298px;
          left: calc(50% - 200px);
          bottom: 50px;
          
        }

        @include tablet {
          background-image: url("../images/zen.svg");
          width: 682px;
          height: 447px;
          left: calc(50% - 336px);
          bottom: -40px;
        }

        @include desktop {
          background-image: url("../images/zen.svg");
          width: 672px;
          height: 437px;
          left: calc(50% - 336px);
          bottom: -30px;
        }
      }
      

      h5 {
        color: $black;
      }

      h2 {
        @include text-shadow($accent);

        br {
          @include mobile-landscape {
           // display: none;
          }

          @include tablet {
            display: none;
          }
        }
      }

      p {
        padding: 0 60px;

        @include tablet {
          padding: 0;
        }
      }
    }
  }
}