@import "base/colors";

footer {
  position: relative;
  z-index: 1;
  padding: 80px 20px 0px;

  @include tablet {
    padding: 50px 60px 40px 30px;
  }

  @include desktop {
    padding: 50px 40px 40px;
  }

  &::after {
    content: '';
    position: absolute;
    width: 100%;
    height: 103%;
    top: -15px;
    right: 5px;
    background-image: url("../images/mobile_footer.svg");
    background-repeat: no-repeat;
    background-size: 98% auto;
    background-position: bottom center;
    z-index: -1;

    @include mobile-xs {
      height: 100%;
      top: 30px;
    }

    @include mobile-landscape {
      top: -18px;
    }

    @include mobile-xs-landscape {
      height: 100%;
      top: 25px;
    }

    @include tablet {
      background-image: url("../images/tablet_footer.svg");
      width: 98%;
      height: 335px;
      top: -5px;
      left: 1%;
    }

    @include iphone-x-landscape {
      background-image: url("../images/mobile_footer.svg");
      top: 0px;
      width: 90%;
      height: 98%;
      left: 5%;
    }

    @include desktop {
      background-image: url("../images/desktop_footer.svg");
      width: 98%;
      height: 421px;
      top: -90px;
    }
  }

  &::before {
    @include divider;
    background-color: $white;
    border-top: 2px solid $black;
    top: -50px;
  
    @include mobile-landscape {
      top: -88px;
    }

    @include mobile-xs-landscape {
      top: -68px;
    }

    @include tablet {
      top: -120px;
    }

    @include desktop {
      top: -250px;
    }

    @include desktop-large {
      top: -300px;
    }
  }

  @include iphone-x-landscape {
    padding-right: env(safe-area-inset-right);
    padding-left: env(safe-area-inset-left);
  }

  .footer-links {
    width: 100%;
    max-width: 1246px;
    margin: 0 auto 10px;
    @include flexbox;
    @include flex-direction(column);

    @include mobile-xs {
      margin: 0 auto 40px;
    }

    @include tablet {
      margin-bottom: 20px;
      @include flex-direction(initial);
    }

    .footer-header {
      color: $black;
      font-size: 14px;
      @include light;
    }

    .footer-content {
      flex-wrap: wrap;
      width: 100%;
      @include flexbox;
      @include flex-direction(row);
      justify-content: space-between;

      @include desktop {
        width: 100%;
      }

      .footer-logo  {

        img {
          @include iphone-x-landscape {
            width: 100%;
          }
        }
        h2 {
          text-align: left;
          color: $black;
          @include text-shadow($accent);
          line-height: 20px;
        }

        p {
          color: $yellow!important;
          margin-top: 0px;
          font-size: 12px;
          line-height: 18px;
          @include light;
          text-transform: none;
        }
      }

      > div:nth-child(odd) {
        @include mobile {
          width: 100%;
        }

        @include tablet {

        }
      }

      .contact {

      }

      .foot-policies {
        @include tablet {
          width: 15%;
         }

         @include iphone-x-landscape {
           width: 100%;
         }
 
         @include desktop {
           width: 20%;
         }
      }

      .company {
        @include tablet {
         width: 15%;
        }

        @include iphone-x-landscape {
          width: 100%;
        }

        @include desktop {
          width: 20%;
        }
        
      }

      > div {
        @include flexbox;
        @include flex-direction(column);
        width: 100%;
        text-align: center;
        margin-bottom: 30px;

        @include tablet {
          text-align: left;
          width: 20%;
        }

        @include iphone-x-landscape {
          width: 100%;
          text-align: center;
        }

        @include desktop {
            width: 20%;
        }

        &.footer-logo {
          width: 50%;
          display: none;

          @include tablet {
            width: 22%;
            display: block;
          }

          @include iphone-x-landscape {
            display: none;
            width: 50%;
          }

          @include desktop {
            width: 36%;
            padding: 10px 95px;
            display: block;
            height: auto;
          }
        }

        hr {
          margin: 2px auto 10px;
          width: 140px;
          background: $accent;

          @include tablet {
            margin-left: -10px;
            margin-top: 2px;
            margin-bottom: 10px;
            width: 170px;
          }

          @include iphone-x-landscape {
            margin: 2px auto 10px;
            width: 140px;
          }
        }

        p {
          color: $black;
          @include regular;
          font-size: 14px;
          line-height: 28px;
          text-transform: uppercase;
          

          @include tablet {
            text-align: left;
          }

          @include iphone-x-landscape {
            text-align: center;
          }
        }

        a {
          @include regular;
          color: $black;
          font-size: 14px;
          line-height: 30px;
          @include transform(translateY(0));
          cursor: pointer;
          text-decoration: none;
          text-transform: uppercase;

          &:hover {
            text-decoration: underline;
            
          }

          &:active,
          &:focus {
            @include transform(translateY(1px));
          }
        }
      }
    }
  }

  &.light {
   // background-color: $white;

    .footer-links {
      .footer-list-header {
        color: $black;
      }

      .footer-content {
        .footer-logo  {
          h2 {
            color: $yellow;
            @include text-shadow($black);
          }
  
          p {
            color: $black;
          }
        }

        > div { 
          hr {
            background: $yellow;
          }

          p {
            color: $black;
          }

          a {
            color: $black;
          }
        }
      }
    }
  }
}

