@import "base";
@import "forms";

.signin {
  margin-top: 0 !important;

  .inner {
    margin-top: 24px;
    margin-bottom: 0;
    padding: 0 0 0 1px;

    @include tablet {
      margin-top: 64px;
    }
  }

  .logo-container {
    margin-bottom: 120px;

    @include tablet {
      margin-bottom: 210px;
    }
  }
}

.login, .signin {
  height: 100%;
  margin-top: 80px;

  .hns-section {
    border-bottom: 0;
    height: 100%;

    .inner {
      width: 95%;
    }

    input[type="text"],
    input[type="password"] {
      margin: 20px;
      width: 250px;
    }

    .btn {
      margin-top: 50px;
      width: 250px;
      height: 5rem;
		  line-height: 4rem;
    }
  }
}

.lightbox {
  margin: 0 auto;
  padding: 30px;
  background: $white;
  border: 1px solid $black;

  @include desktop {
    width: 50%;
  }

  .lightbox-header {
    margin-bottom: 40px;
  }

  form {
    @include flexbox;
    @include flex-direction(column);
    align-items: center;
    padding: 0 20px 20px;
    
  }

  form input[type="text"],
  form input[type="password"]
  form textarea {
    width: 100%;
    margin-bottom: 20px;
  }

  .btn {
    margin-top: 20px;
    width: 280px;
  }
  
}
