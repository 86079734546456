$mobile-max: 767px;
$tablet-min: 768px;
$desktop-min: 1280px;
$desktop-large: 1920px;


@mixin mobile-xs() {
	@media only screen 
	and (min-device-width : 320px) 
	and (max-device-width : 359px) {
		@content;
	}
}

@mixin mobile-xs-landscape() { 
	@media only screen 
		and (min-device-width : 320px) 
		and (max-device-width : 359px)
		and (orientation : landscape) {
			@content;
		}
}

@mixin mobile() {
	@media only screen and (max-width: $mobile-max) {
		@content;
	}
}

@mixin mobile-plus() {
	@media only screen 
	and (min-device-width : 414px) 
	and (max-device-width : 736px)  {
		@content;
	}
}

@mixin mobile-plus-landscape() {
	@media only screen 
	and (min-device-width : 414px) 
	and (max-device-width : 736px)
	and (orientation : landscape)  {
		@content;
	}
}

@mixin tablet-small() {
	@media only screen and (device-width: $tablet-min) and (device-height: 1024px) {
		@content;
	}
}

@mixin tablet-ex() {
	@media only screen and (device-width: 834px) and (device-height: 1112px) {
		@content;
	}
}

@mixin tablet() {
	@media only screen and (min-width: $tablet-min) {
		@content;
	}
}

@mixin tablet-large() {
	@media only screen and (min-device-width: 1024px) and (max-device-height: 1366px)  and (-webkit-min-device-pixel-ratio: 1.5) {
		@content;
	}
}

@mixin tablet-range() {
	@media only screen and (min-device-width: $tablet-min) and (max-device-width: $desktop-min - 1) {
		@content;
	}
}

@mixin tablet-landscape() {
	@media only screen and (min-device-width: $tablet-min) and (max-device-width: $desktop-min - 1) and (orientation : landscape) {
		@content;
	}
}

@mixin desktop() {
	@media only screen and (min-width: $desktop-min) {
		@content;
	}
}

@mixin desktop-large() {
	@media only screen and (min-width: $desktop-large) {
		@content;
	}
}

@mixin device() {
	@media only screen and (max-width: $desktop-min - 1) {
		@content;
	}
}

@mixin mobile-landscape() {
	@media only screen and (max-width: $mobile-max) and (orientation : landscape) {
		@content;
	}
}

@mixin mobile-2x() {
	@media only screen and (-webkit-min-device-pixel-ratio : 2)  {
		@content;
	}
}

@mixin mobile-3x() {
	@media
		only screen and (-webkit-min-device-pixel-ratio: 3),
		only screen and (   min--moz-device-pixel-ratio: 3),
		only screen and (     -o-min-device-pixel-ratio: 3/1),
		only screen and (        min-device-pixel-ratio: 3),
		only screen and (                min-resolution: 458dpi),
		only screen and (                min-resolution: 3dppx) { 
			@content;
	}
}

@mixin mobile-3x-landscape() {
	@media
		only screen and (-webkit-min-device-pixel-ratio: 3) and (orientation : landscape),
		only screen and (   min--moz-device-pixel-ratio: 3) and (orientation : landscape),
		only screen and (     -o-min-device-pixel-ratio: 3/1) and (orientation : landscape),
		only screen and (        min-device-pixel-ratio: 3) and (orientation : landscape),
		only screen and (                min-resolution: 458dpi) and (orientation : landscape),
		only screen and (                min-resolution: 3dppx) and (orientation : landscape) { 
			@content;
	}
}

@mixin iphone-x() {
	@media only screen 
		and (device-width : 375px) 
		and (device-height : 812px)
		and (-webkit-device-pixel-ratio : 3) { 
			@content;
		}
}

@mixin iphone-x-landscape() {
	@media only screen 
		and (device-width : 375px) 
		and (device-height : 812px) 
		and (-webkit-device-pixel-ratio : 3)
		and (orientation : landscape) { 
			@content;
		}
}
                                