#mainNavMobile {
  background: rgba($yellow, 1);
  position: fixed;
  z-index: 10;
  top: 0;
  bottom: -100px;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  -moz-transition: -moz-transform 0.3s ease-in-out;
  -o-transition: -o-transform 0.3s ease-in-out;
  -webkit-transition: -webkit-transform 0.3s ease-in-out;
  transition: transform 0.3s ease-in-out;
  @include transform(translate3d(100%, 0, 0));
  @include bold;
  letter-spacing: 2px;

  @include tablet {
    background: rgba($yellow, 0.95);
  }

  .inner {
    position: relative;
    margin: 0 auto;
    padding: 100px 15.5vw;

    .close-btn {
      margin-top: env(safe-area-inset-top);
      top: 15px;
      right: 20px;

      @include tablet {
        right: 0px;
      }
    }
  }

  a {
    color: $black;
  }

  .nav-links li {
    font-size: 2.2rem;
    line-height: 2.9rem;
    margin-bottom: 30px;
   
    text-align: left;
    text-align: center;

    @include tablet {
      font-size: 2.6rem;
    }

    &.secondary {
      a {
        font-size: 1.8rem;
        color: $black;
        @include regular;
      }
    }
  }

  .nav-links li a {
    color: $black;
    @include text-shadow($accent);
    text-decoration: none;
    display: block;
    cursor: pointer;
  }

  &.show {
    display: block;
    animation: fade-in 0.3s $ease-out-cubic;
    animation-fill-mode : both;
    @include transform(translateZ(0));
  
    nav {
      animation: slide-in-from-right 0.4s $ease-out-cubic;
      animation-fill-mode : both;
    }
  } 
  
  .bottom {
    position: absolute;
    bottom: 0px;
    width: 100%;
    text-align: center;
    z-index: 100;
    left: 0;
  }
}

