/* Reset */

* {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font: inherit;
	vertical-align: baseline;
	box-sizing: border-box;
	-moz-box-sizing: border-box;
	-webkit-box-sizing: border-box;
}

html,
body,
body div,
span,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
abbr,
address,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
samp,
small,
strong,
sub,
sup,
var,
b,
i,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
figure,
footer,
header,
hgroup,
menu,
nav,
section,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent
}

article,
aside,
figure,
footer,
header,
hgroup,
nav,
section,
main {
  display: block
}

object,
embed {
  max-width: 100%
}

body *, body a:focus {
  outline: none;
}

ul,
ol,
nav ul,
nav ol {
  list-style: none;
  list-style-image: none
}

::-moz-selection {
  background: $accent;
  color: $white;
  text-shadow: none
}

::selection {
  background: $accent;
  color: $white;
  text-shadow: none
}

blockquote,
q {
  quotes: none
}

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none
}

a {
  margin: 0;
  padding: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent
}

a:hover,
a:active,
a:focus {
  outline: none
}

del {
  text-decoration: line-through
}

abbr[title],
dfn[title] {
  border-bottom: 1px dotted black;
  cursor: help
}

table {
  border-collapse: collapse;
  border-spacing: 0;
  font-size: inherit;
  font: 100%
}

th {
  font-weight: bold;
  vertical-align: bottom
}

td {
  font-weight: normal;
  vertical-align: top
}

td,
td img {
  vertical-align: top
}

pre {
  white-space: pre;
  white-space: pre-wrap;
  white-space: pre-line;
  word-wrap: break-word
}

strong,
th {
  font-weight: bold
}

sub,
sup {
  font-size: 70%;
  line-height: 0;
  position: relative
}

sup {
  top: -0.5em;
  left: -0.1em;
}

sub {
  bottom: -0.25em
}

pre,
samp {
  font-family: monospace, sans-serif
}

@font-face {
  font-family: "Graphik";
  src: url("../src/assets/fonts/Graphik-Regular.eot");
  src: local("Graphik Regular"), local("Graphik-Regular"),
      url( "../src/assets/fonts/Graphik-Regular.eot?#iefix") format("embedded-opentype"),
      url("../src/assets/fonts/Graphik-Regular.woff2") format("woff2"),
      url("../src/assets/fonts/Graphik-Regular.woff") format("woff"),
      url("../src/assets/fonts/Graphik-Regular.ttf") format("truetype"),
      url("../src/assets/fonts/Graphik-Regular.svg#Graphik-Regular") format("svg");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Graphik";
  src: url("../src/assets/fonts/Graphik-Semibold.eot");
  src: local("Graphik Semibold"), local("Graphik-Semibold"),
      url("../src/assets/fonts/Graphik-Semibold.eot?#iefix") format("embedded-opentype"),
      url("../src/assets/fonts/Graphik-Semibold.woff2") format("woff2"),
      url("../src/assets/fonts/Graphik-Semibold.woff") format("woff"),
      url("../src/assets/fonts/Graphik-Semibold.ttf") format("truetype"),
      url("../src/assets/fonts/Graphik-Semibold.svg#Graphik-Semibold") format("svg");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "Graphik";
  src: url("../src/assets/fonts/Graphik-Light.eot");
  src: local("Graphik Light"), local("Graphik-Light"),
      url("../src/assets/fonts/Graphik-Light.eot?#iefix") format("embedded-opentype"),
      url("../src/assets/fonts/Graphik-Light.woff2") format("woff2"),
      url("../src/assets/fonts/Graphik-Light.woff") format("woff"),
      url("../src/assets/fonts/Graphik-Light.ttf") format("truetype"),
      url("../src/assets/fonts/Graphik-Light.svg#Graphik-Light") format("svg");
  font-weight: 300;
  font-style: normal;
}