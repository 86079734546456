@mixin clearfix {
	&:before, &:after {
		content: "";
		display: table;
	}

	&:after {
		clear: both;
		height: 0;
		margin: 0;
		margin-top: -3px;
	}
}

@mixin decorations($position, $size) {
  background-image: url("../../images/background.svg");
  background-repeat: no-repeat;
  background-position-x: center;
  background-position-y: $position;
  background-size: $size;
}

@mixin divider {
  content: '';
  display: block;
  height: 100%;
  width: 200%;
  position: absolute;
  left: 50%;
  -webkit-transform: rotate(-6.5deg) translateX(-50%);
  -ms-transform: rotate(-6.5deg) translateX(-50%);
  transform: rotate(-6.5deg) translateX(-50%);
  -webkit-transform-origin: center top;
  -ms-transform-origin: center top;
  transform-origin: center top;
  z-index: -1;
}

@mixin text-shadow ($color){
  text-shadow: 2px 2px 0px $color;
}

@mixin flexbox {
  display: table-cell;
  display: -webkit-box;      /* OLD - iOS 6-, Safari 3.1-6 */
  display: -moz-box;         /* OLD - Firefox 19- (buggy but mostly works) */
  display: -ms-flexbox;      /* TWEENER - IE 10 */
  display: -webkit-flex;     /* NEW - Chrome */
  display: flex;             /* NEW, Spec - Opera 12.1, Firefox 20+ */
}

@mixin flex {
  -webkit-box-flex: 1;      /* OLD - iOS 6-, Safari 3.1-6 */
  -moz-box-flex: 1;         /* OLD - Firefox 19- */
  width: 20%;               /* For old syntax, otherwise collapses. */
  -webkit-flex: 1;          /* Chrome */
  -ms-flex: 1;              /* IE 10 */
  flex: 1;     
}

@mixin flex-direction($props) {
  -webkit-flex-direction: $props;
	  -moz-flex-direction: $props;
	  -ms-flex-direction: $props;	
      flex-direction: $props;
}

@mixin transition($one, $two: null, $three: null) {
  -webkit-transition: $one, $two, $three;
  -moz-transition:    $one, $two, $three;
  -ms-transition:     $one, $two, $three;
  -o-transition:      $one, $two, $three;
  transition:         $one, $two, $three;
}

@mixin transition-delay($delay...) {
  -moz-transition-delay:    $delay;
  -o-transition-delay:      $delay;
  -webkit-transition-delay: $delay;
  transition-delay:         $delay;
}

@mixin transition-transform($one, $two) {
  -webkit-transition: $one, -webkit-transform $two;
  -moz-transition: $one, -moz-transform $two;
  -o-transition: $one, -o-transform $two;
  transition: $one, transform $two;
}

@mixin transform($props) {
  -moz-transform: $props;
  -ms-transform: $props;
  -webkit-transform: $props;
  transform: $props;
}

@mixin backface($props) {
  -webkit-backface-visibility: $props;
    -moz-backface-visibility: $props;
      backface-visibility: $props;
}

@mixin user-select() {
  -moz-user-select: -moz-none;
  -ms-user-select: none;
  -webkit-user-select: none;
  user-select: none;
}

@mixin box-shadow($props) {
  -webkit-box-shadow: $props;
          box-shadow: $props;
}

@mixin animation($props) {
  -webkit-animation: $props;
     -moz-animation: $props;
          animation: $props;
}
//--------------------------------------------------------------------------------
// ------------ Metarial Design Boxshadow Mixin
//--------------------------------------------------------------------------------
// Created by @KingRayhan
// @url rayhan.info
@mixin mdboxshadow($depth:1, $color: black) {
  @if $depth==0 {
    box-shadow: 0 1px 3px rgba($color, 0.06), 0 1px 2px rgba($color, 0.12);
  }
  @if $depth==1 {
    box-shadow: 0 1px 3px rgba($color, 0.12), 0 1px 2px rgba($color, 0.24);
  }
  @else if $depth==2 {
    box-shadow: 0 3px 6px rgba($color, 0.16), 0 3px 6px rgba($color, 0.23);
  }
  @else if $depth==3 {
    box-shadow: 0 10px 20px rgba($color, 0.19), 0 6px 6px rgba($color, 0.23);
  }
  @else if $depth==4 {
    box-shadow: 0 14px 28px rgba($color, 0.25), 0 10px 10px rgba($color, 0.22);
  }
  @else if $depth==5 {
    box-shadow: 0 19px 38px rgba($color, 0.30), 0 15px 12px rgba($color, 0.22);
  }
  transition: all 0.2s cubic-bezier(.25, .8, .25, 1);
}

