@import 'base';
@import 'base/easings';
@import 'base/typography';
@import 'forms';


.signup {
  height: 100%;
  padding-top: 135px;
  position: relative;

  @include mobile {
    &::before {
      content: '';
      position: absolute;
      height: 40px;
      background-color: $yellow;
      top: 95px;
      width: 100%;
    }
  }

  @include iphone-x-landscape {
    padding-top: 0px;
  }

  @include tablet {
    padding-top: 0px;
  }

  //quejesto
  input.highlight {
    border-color: $alert;
  }

  header {
    .right {
      display: none;
    }
  }

  h5 {
    color: $black;
  }
  
  .hns-section {
    padding-top: 60px;
    position: relative;
    background-color: transparent;

    &.thanks {
      &::before {
        @include tablet {
          height: 250px;
        }
      }
      &::after {
        @include tablet {
          top: 40px;
        }
      }
    }

    &::before {
      content: '';
      display: block;
      height: 100%;
      width: 120%;
      position: absolute;
      left: 50%;
      top: -60%;
      @include divider;
      z-index: 0;
      background-color: $yellow;
    }

    &::after {
      content: '';
      display: block;
      width: 100%;
      height: 420px;
      position: absolute;
      
      top: 0;
      z-index: 1;

      @include tablet {
        height: 380px;
        @include decorations(55px, 95% auto);
      }

      @include desktop {
        height: 380px;
        @include decorations(55px, 80% auto);
      }
    }
  
    .inner {
      max-width: 875px;
      

      &::before {
        content: '';
        background-image: url("../images/signup.svg");
        width: 110px;
        height: 141px;
        position: absolute;
        right: calc(25% - 75px);
        top: 100px;
        z-index: 1;

        @include tablet {
          top: 0px;
          width: 163px;
          height: 210px;
          right: calc(25% - 81px);
        }
      }

      > div {
        margin-bottom: 120px;
      
        .container {
          margin: 25px 0 20px;
        }
      }
    }
  }

  .price {
    text-align: center;
    padding: 10px 0 0 35px;
    margin: 0 0 15px 0;
  
    span {
      @include bold;
      font-size: 4.563em;
      @include text-shadow($accent);
      position: relative;

      > span {
        font-size: 41px;
        position: absolute;
        left: -15px;
        top: 10px;
      }
      
      small {
        color: $white;
        @include light;
        font-size: 16px;
        text-shadow: none;
      }
    }
  }

  .form {
    background: $white;
    padding: 10px 10px 10px 20px;
    margin: 30px 15px 0;
    position: relative;

    @include mobile-xs-landscape {
      margin: 30px 45px 0;
    }

    @include mobile-landscape {
      margin: 30px 60px 0;
    }

    @include tablet  {
      margin: 30px 50px 0;
      padding: 10px 25px 10px 40px;
    }

    @include desktop  {
      margin: 30px 0 0 0;
      padding: 10px 25px 10px 40px;
    }

    &::after {
      content: '';
      position: absolute;
      z-index: -1;
      width: calc(100% + 8px);
      height: 98%;
      bottom: -7px;
      left: -4px;
      background-color: $black;
      

      @include tablet {
        //width: 890px;
        height: 95%;
        background-color: $black;
        bottom: -7px;
        left: -7px;
        width: calc(100% + 14px);
      }
    }
  }
}



