@import 'base';


.policies {
  height: 100%;
  padding-top: 135px;
  position: relative;

  @include mobile {
    &::before {
      content: '';
      position: absolute;
      height: 40px;
      background-color: $yellow;
      top: 95px;
      width: 100%;
    }
  }

  @include iphone-x-landscape {
    padding-top: 0px;
  }

  @include tablet {
    padding-top: 0px;
  }

  ul, li {
    list-style: none;
  }

  h5 {
    color: $black;
    padding: 0 40px;
  }

  .hns-section {
    padding-top: 60px;
    position: relative;
    height: 580px;
    background-color: transparent;

    @include mobile-landscape {
      height: 610px;
    }
    
    @include mobile-xs-landscape {
      height: 515px;
    }

    @include tablet {
      height: 650px;
    }

    @include desktop {
      height: 740px;
    }
  
    &::before {
      content: '';
      display: block;
      height: 100%;
      width: 120%;
      position: absolute;
      left: 50%;
      top: -50%;
      @include divider;
      z-index: 0;
      background-color: $yellow;
    }

    &::after {
      content: '';
      display: block;
      width: 100%;
      height: 360px;
      position: absolute;
      top: 0;
      z-index: 1;

      @include tablet {
        height: 380px;
        @include decorations(55px, 80% auto);
      }
    }
  
    .inner {
      max-width: 875px;

      .btn {
        margin: 1em auto;
        position: absolute;
        z-index: 10;
        left: calc(50% - 125px);
        bottom: 180px;
      }

      img {
        margin-top: 20px;
        margin-left: -40px;
        width: 60%;
        height: auto;

        @include mobile-landscape {
          width: 35%;
        }

        @include tablet {
          width: 35%;
        }

        @include desktop {
          width: 35%;
        }
      }

      a {
        color: $black;
       
      }

      h6 {
        font-size: 1.000em;
        margin-bottom: 10px;
      }
    }
  }
}