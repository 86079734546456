@import 'base';
@import "base/reset";
@import 'base/typography';
@import "base/buttons";
@import 'base/navigation';
@import "header";
@import "footer";
@import "ie";

html,body{
  height: 100%
}

html {
  -webkit-text-size-adjust: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 62.5%; /* Sets up the Base 10 stuff */
}

body {
  background: $white;
  @include backface(hidden);
  overflow-x: hidden;
  color: $black;
  @include regular;
  font-size: 1.6rem;
  line-height: 1.3;
}

#app {
  height: auto;
  overflow: hidden;
}

.main-wrapper, .main-content {
  height: 100%;
}

a {
  color: $yellow;
}

a:hover {
  color: $accent;
}

a:hover
a:focus, 
a:active {
  text-decoration: none;
}

p {
  @include light;
  color: $black;
  text-align: center;
  font-size: 0.875em;
  line-height: 1.5em;

  @include tablet {
    font-size: 0.813em;
  }

  small {
    font-size: 12px;
    line-height: 1.000em;
  }
}

h1, h2, h3, h4, h5, h6 {
  color: $black;
  text-align: center;
  
}

h1 {
  @include bold;
  font-size: 2.813em; 
  line-height: 1.3em;
  padding: 15px 20px 5px;
  @include text-shadow($accent);

  @include mobile-xs {
    font-size: 2.5em; 
  }

  @include tablet {
    font-size: 3.000em; 
    padding: 15px 0 5px;
  }

  @include desktop {
    font-size: 3.313em; 
    padding: 15px 0 5px;
  }
}

h2 {
  @include bold;
  font-size: 2.188em;
  margin: 5px 0 10px;
  @include text-shadow($yellow);

  @include mobile-xs {
    font-size: 2.000em;
  }

  @include tablet {
    font-size: 2.813em;
  }
}

h3 {
  @include regular;
  font-size: 2.000em;
  letter-spacing: -1px;
}

h4 {
  @include light;
  font-size: 1.125em;
  padding: 0 40px;
  color: $accent;

  @include tablet {
    font-size: 1.375em;
    padding: 0;
  }
}

h5 {
  @include light;
  font-size: 1.063em;
  color: $yellow;
  line-height: 1.563em;

  @include mobile-xs {
    font-size: 1.000em;
  }
}

h6 {
  @include bold;
  font-size: 0.938em;
  color: $black;
}

.hns-section {
  //background-color: $white;
  position: relative;
  height: auto;
  text-align: center;
  @include flexbox;
  //align-items: center;
}

.inner {
  width: 100%;
  height: auto;
  margin: 0 auto;
  max-width: 770px;
  z-index: 2;
  position: relative;

  @include tablet {
    max-width: 700px;
  }

  @include tablet-large {
    max-width: 900px;
  }

  @include tablet-landscape {
    max-width: 1000px;
  }
  
  @include desktop {
    max-width: 1063px;
  }
  @include iphone-x-landscape {
    padding: env(safe-area-inset-top) env(safe-area-inset-right) env(safe-area-inset-bottom) env(safe-area-inset-left);
  }
}

.hide-mobile {
  @include mobile {
    display: none
  }

  @include tablet {
    display: none
  }

  @include tablet-landscape {
    display: block
  }
}

.hide-desktop {
  @include desktop {
    display: none;
  }
}

hr {
  display: block;
  height: 2px;
  background-color: #000000;
  width: 80px;
  margin: 28px auto 30px;

  @include mobile-landscape {
    margin: 18px auto 20px;
  }

  @include tablet {
    width: 160px;
  }
}
