@import "base";
@import "base/easings";


.support {
  height: 100%;
  padding-top: 135px;
  position: relative;

  @include mobile {
    &::before {
      content: '';
      position: absolute;
      height: 40px;
      background-color: $yellow;
      top: 95px;
      width: 100%;
    }
  }

  @include iphone-x-landscape {
    padding-top: 0px;
  }

  @include tablet {
    padding-top: 0px;
  }

  h5 {
    color: $black;
    padding: 0 40px;
  }

  .hns-section {
    padding-top: 60px;
    position: relative;
    background-color: transparent;
  
    &::before {
      content: '';
      display: block;
      height: 100%;
      width: 120%;
      position: absolute;
      left: 50%;
      top: -60%;
      @include divider;
      z-index: 0;
      background-color: $yellow;

    }

    &::after {
      content: '';
      display: block;
      width: 100%;
      background: $yellow;
      
      height: 340px;
      position: absolute;
      top: 0;
      z-index: 1;
     
      @include tablet {
        height: 320px;
        @include decorations(55px, 95% auto);
      }

      @include desktop {
        height: 320px;
        @include decorations(55px, 80% auto);
      }
    }
  
    .inner {
      max-width: 875px;

      > div {
        margin-bottom: 60px;

        @include tablet {
          margin-bottom: 120px;
        }
      
        .container {
          margin: 40px 0 20px;
          
        }
      }

      a {
        color: $black;
      }
    }
  }

  .form-container {
    @include flexbox;
    @include flex-direction(column);
    -ms-flex-align: center;
    align-items: center;
  
    form {
      width: 100%;
      position: relative;
  
      input[type="text"],
      input[type="email"] {
        width: 100%;
        margin-bottom: 10px;
      }
      
      textarea {
        height: auto;
        margin: 0 0 20px 0;
        width: 100%;
        display: block;
        flex-shrink: 1;
      }
    }

    .form {
      background-color: $white;
      margin: 50px 15px 0;
      position: relative;
      padding: 50px 10px 10px 20px;

      @include mobile-xs-landscape {
        margin: 30px 45px 10px;
      }
  
      @include mobile-landscape {
        margin: 30px 60px 10px;
      }

      @include tablet  {
        padding: 50px 25px 10px 40px;
        margin: 50px 50px 0;
      }

      @include desktop  {
        padding: 50px 25px 10px 40px;
        margin: 50px 15px 0;
      }

      &::after {
        content: '';
        position: absolute;
        z-index: -1;
        width: calc(100% + 8px);
        height: 98%;
        bottom: -7px;
        left: -4px;
        background-color: $black;
        
  
        @include tablet {
          height: 95%;
          background-color: $black;
          bottom: -7px;
          left: -7px;
          width: calc(100% + 14px);
        }
      }
    }

    .border {
      border: 2px solid $yellow;
      width: 99.9%;
      height: 50px;
      position: absolute;
      top: -3px;
      left: -3px;
    }

    .borderArea {
      border: 2px solid $yellow;
      width: 99.9%;
      height: 131px;
      position: absolute;
      top: -4px;
      left: -4px;
    }
  }

  .service {
    padding-top: 25px;

    @include tablet {
      padding: 25px 0 40px;
    }

    li {
      position: relative;
      cursor: pointer;
      margin: 0 10px;
    }

    img {
      margin-bottom: 20px;
      width: 132px;
      height: auto;
      @include mdboxshadow(2);

      @include mobile-xs {
        width: 128px;
      }

      @include tablet {
        width: 144px;
      }

      &:hover {
        @include mdboxshadow(3);
        @include transition(all 0.4s);
      }
    }
  }
}
