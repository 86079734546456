@import 'colors';

$graphik: "Graphik", "Lato", "Helvetica Neue", Arial, sans-serif;

@mixin bold() {
  font-family: $graphik;
  font-weight: 600;
}

@mixin regular () {
  font-family: $graphik;
  font-weight: 400;
}

@mixin light() {
  font-family: $graphik;
  font-weight: 300;
}
