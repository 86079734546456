@import "base";

.FourOhFour {
  height: 100%;
  padding-top: 135px;
  position: relative;

  @include mobile {
    &::before {
      content: '';
      position: absolute;
      height: 40px;
      background-color: $yellow;
      top: 95px;
      width: 100%;
    }
  }

  @include tablet {
    padding-top: 0;
  }

  h5 {
    color: $black;
    padding: 0 40px;
  }

  .hns-section {
    padding-top: 60px;
    position: relative;
    height: 730px;
  
    &::before {
      content: '';
      display: block;
      width: 100%;
      background: $yellow;
      
      height: 340px;
      position: absolute;
      top: 0;
      z-index: 1;

      @include tablet {
        height: 320px;
        @include decorations(55px, 80% auto);
      }
    }

    &::after {
      content: '';
      display: block;
      height: 150px;
      width: 120%;
      position: absolute;
      left: 50%;
      top: 220px;
      -webkit-transform: rotate(-6.5deg) translateX(-50%);
      -ms-transform: rotate(-6.5deg) translateX(-50%);
      transform: rotate(-6.5deg) translateX(-50%);
      -webkit-transform-origin: center top;
      -ms-transform-origin: center top;
      transform-origin: center top;
      z-index: 0;
      background-color: $yellow;

      @include tablet {
        top: 120px;
        height: 200px;
      }
    }
  
    .inner {
      max-width: 875px;

      .btn {
        margin: 1em auto;
        position: absolute;
        z-index: 10;
        left: calc(50% - 105px);
        bottom: 180px;

        @include tablet {
          left: calc(50% - 125px);
        }
      }

      img {
        width: 120%;
        margin-left: -20px;

        @include tablet {
          margin-top: 20px;
          margin-left: 0;
          width: auto;
        }
      }

      a {
        color: $black;
      }
    }
  }
}