input,
textarea,
select {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  border-radius: 0;
}

textarea {
  overflow: auto;
}

textarea,
input[type="date"],
input[type="datetime"],
input[type="datetime-local"],
input[type="email"],
input[type="month"],
input[type="number"],
input[type="password"],
input[type="search"],
input[type="tel"],
input[type="text"],
input[type="time"],
input[type="url"],
input[type="week"] {
  @include bold;
  font-size: 1.4rem;
  line-height: 2rem;
  background-color: transparent;
  color: $black;
  border: 2px $black solid;
  padding: 14px 10px;
  resize: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  @include transition(border-color 0.1s, color 0.1s, padding 0.1s);
  width: 98.5%;
  position: relative;
}

textarea:-moz-placeholder,
input[type="date"]:-moz-placeholder,
input[type="datetime"]:-moz-placeholder,
input[type="datetime-local"]:-moz-placeholder,
input[type="email"]:-moz-placeholder,
input[type="month"]:-moz-placeholder,
input[type="number"]:-moz-placeholder,
input[type="password"]:-moz-placeholder,
input[type="search"]:-moz-placeholder,
input[type="tel"]:-moz-placeholder,
input[type="text"]:-moz-placeholder,
input[type="time"]:-moz-placeholder,
input[type="url"]:-moz-placeholder,
input[type="week"]:-moz-placeholder,
select:-moz-placeholder {
  color: $black;
  text-transform: uppercase;
  vertical-align: baseline;
  
  @include mobile {
    font-size: 1.1rem;
    line-height: 2.3rem;
  }
}

textarea::-moz-placeholder,
input[type="date"]::-moz-placeholder,
input[type="datetime"]::-moz-placeholder,
input[type="datetime-local"]::-moz-placeholder,
input[type="email"]::-moz-placeholder,
input[type="month"]::-moz-placeholder,
input[type="number"]::-moz-placeholder,
input[type="password"]::-moz-placeholder,
input[type="search"]::-moz-placeholder,
input[type="tel"]::-moz-placeholder,
input[type="text"]::-moz-placeholder,
input[type="time"]::-moz-placeholder,
input[type="url"]::-moz-placeholder,
input[type="week"]::-moz-placeholder,
select::-moz-placeholder,
select option:first-child {
  color: $black;
  opacity: 1;
  text-transform: uppercase;
  vertical-align: baseline;
  
  @include mobile {
    font-size: 1.1rem;
    line-height: 2.3rem;
  }
}

textarea:-ms-input-placeholder,
input[type="date"]:-ms-input-placeholder,
input[type="datetime"]:-ms-input-placeholder,
input[type="datetime-local"]:-ms-input-placeholder,
input[type="email"]:-ms-input-placeholder,
input[type="month"]:-ms-input-placeholder,
input[type="number"]:-ms-input-placeholder,
input[type="password"]:-ms-input-placeholder,
input[type="search"]:-ms-input-placeholder,
input[type="tel"]:-ms-input-placeholder,
input[type="text"]:-ms-input-placeholder,
input[type="time"]:-ms-input-placeholder,
input[type="url"]:-ms-input-placeholder,
input[type="week"]:-ms-input-placeholder,
select:-ms-input-placeholder {
  color: $black;
  text-transform: uppercase;
  vertical-align: baseline;

  @include mobile {
    font-size: 1.1rem;
    line-height: 2.3rem;
  }
}

textarea::-webkit-input-placeholder,
input[type="date"]::-webkit-input-placeholder,
input[type="datetime"]::-webkit-input-placeholder,
input[type="datetime-local"]::-webkit-input-placeholder,
input[type="email"]::-webkit-input-placeholder,
input[type="month"]::-webkit-input-placeholder,
input[type="number"]::-webkit-input-placeholder,
input[type="password"]::-webkit-input-placeholder,
input[type="search"]::-webkit-input-placeholder,
input[type="tel"]::-webkit-input-placeholder,
input[type="text"]::-webkit-input-placeholder,
input[type="time"]::-webkit-input-placeholder,
input[type="url"]::-webkit-input-placeholder,
input[type="week"]::-webkit-input-placeholder,
select::-webkit-input-placeholder {
  color: $black;
  text-transform: uppercase;
  vertical-align: baseline;
  
  @include mobile {
    vertical-align: super;
    font-size: 1.1rem;
    line-height: 2.3rem;
  }
}

textarea:active,
textarea:focus,
textarea:hover,
input[type="date"]:active,
input[type="date"]:focus,
input[type="date"]:hover,
input[type="datetime"]:active,
input[type="datetime"]:focus,
input[type="datetime"]:hover,
input[type="datetime-local"]:active,
input[type="datetime-local"]:focus,
input[type="datetime-local"]:hover,
input[type="email"]:active,
input[type="email"]:focus,
input[type="email"]:hover,
input[type="month"]:active,
input[type="month"]:focus,
input[type="month"]:hover,
input[type="number"]:active,
input[type="number"]:focus,
input[type="number"]:hover,
input[type="password"]:active,
input[type="password"]:focus,
input[type="password"]:hover,
input[type="search"]:active,
input[type="search"]:focus,
input[type="search"]:hover,
input[type="tel"]:active,
input[type="tel"]:focus,
input[type="tel"]:hover,
input[type="text"]:active,
input[type="text"]:focus,
input[type="text"]:hover,
input[type="time"]:active,
input[type="time"]:focus,
input[type="time"]:hover,
input[type="url"]:active,
input[type="url"]:focus,
input[type="url"]:hover,
input[type="week"]:active,
input[type="week"]:focus,
input[type="week"]:hover,
select:hover,
select:focus,
select:active {
  border-color: $black
}

textarea:disabled,
input[type="date"]:disabled,
input[type="datetime"]:disabled,
input[type="datetime-local"]:disabled,
input[type="email"]:disabled,
input[type="month"]:disabled,
input[type="number"]:disabled,
input[type="password"]:disabled,
input[type="search"]:disabled,
input[type="tel"]:disabled,
input[type="text"]:disabled,
input[type="time"]:disabled,
input[type="url"]:disabled,
input[type="week"]:disabled,
select:disabled {
  color: $black;
  border-color: $black;
  background-color: transparent;
  text-transform: uppercase;
  font-size: 1.1rem;

  @include tablet {
    font-size: 1.4rem;
  }
}

textarea.with-error,
input[type="date"].with-error,
input[type="datetime"].with-error,
input[type="datetime-local"].with-error,
input[type="email"].with-error,
input[type="month"].with-error,
input[type="number"].with-error,
input[type="password"].with-error,
input[type="search"].with-error,
input[type="tel"].with-error,
input[type="text"].with-error,
input[type="time"].with-error,
input[type="url"].with-error,
input[type="week"].with-error {
  border-color: $yellow !important
}

input[type="number"] {
  -moz-appearance: textfield;
  text-transform: uppercase;
  //color: $black;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -moz-appearance: none;
  -webkit-appearance: none
}





select {
  @include bold;
  font-size: 1.1rem;
  line-height: 2.3rem;
  background: $white right 15px center no-repeat;
  padding: 14px 35px 16px 10px;
  border: 2px $black solid;
  text-indent: 0.01px;
  -moz-appearance: none;
  -webkit-appearance: none;
  text-transform: uppercase;
  border-radius: 0;
  color: $black;
  background-image: linear-gradient(45deg, transparent 50%, $yellow 50%), linear-gradient(135deg, $yellow 50%, transparent 50%);
  background-position: calc(100% - 22px) calc(48% + 2px), calc(100% - 15px) calc(48% + 2px);
  background-size: 8px 8px, 8px 8px;
  background-repeat: no-repeat;
  width: 97%;

  @include tablet {
    padding: 14px 35px 16px 15px;
    font-size: 1.4rem;
    line-height: 2rem;
  }

  &:focus {
    background-image: linear-gradient(45deg, $yellow 50%, transparent 50%), linear-gradient(135deg, transparent 50%, $yellow 50%);
    background-position: calc(100% - 15px) 48%, calc(100% - 22px) 48%;
    background-size: 8px 8px, 8px 8px;
    background-repeat: no-repeat;
    color: $yellow;
  }
}

select::-ms-expand {
  display: none
}

select:disabled {
  color: $black;
}

select option:not(:checked) {
  color: $black !important;
}

select option:checked {
  color: $yellow !important;
}

input[type="radio"],
input[type="checkbox"] {
  margin: 10px;
  width: auto;
  height: auto;
}



.input-label {
  position: relative
}


// Errors
// General form error
.formError {
  color: $alert;
  font-size: 1.3rem;
  background-color: $white;
  display: table;
  position: relative;
  bottom: -20px;
  width: 100%;
  height: auto;
  padding: 10px 20px;
  z-index: 100;
  border-top: 3px solid $alert;
  @include mdboxshadow(2);
  -webkit-animation: 0.5s anim-lightspeed-in-top ease forwards;
      -moz-animation: 0.5s anim-lightspeed-in-top ease forwards;
        -o-animation: 0.5s anim-lightspeed-in-top ease forwards;
          animation: 0.5s anim-lightspeed-in-top ease forwards;
  
  
  &:after {
    content: "";
    width: 0;
    height: 0;
    position: absolute;
    border-bottom-color: $alert;
    border-bottom-style: solid;
    border-bottom-width: 6px;
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    top: -7px;
    left: -webkit-calc(50% - 8px);
    left: -moz-calc(50% - 8px);
    left: calc(50% - 8px);
    -webkit-transform: rotate(-90);
        -ms-transform: rotate(-90);
            transform: rotate(-90);
  }
}

// Field error
.error {
  color: $alert;
  font-size: 1.3rem;
  background-color: $white;
  display: block;
  position: absolute;
  padding: 5px 20px;
  z-index: 100;
  @include mdboxshadow(2);
  
  &:after {
    content: "";
    width: 0;
    height: 0;
    position: absolute;
    margin-left: -8px;
    -webkit-transform: rotate(-90);
        -ms-transform: rotate(-90);
            transform: rotate(-90);
  }
  
  &.right {
    top: 12px;
    border-left: 3px solid $alert;
    -webkit-animation: 1s anim-lightspeed-in ease forwards;
      -moz-animation: 1s anim-lightspeed-in ease forwards;
        -o-animation: 1s anim-lightspeed-in ease forwards;
            animation: 1s anim-lightspeed-in ease forwards;

    &:after {
      border-right-color: $alert;
      border-right-style: solid;
      border-right-width: 6px;
      border-bottom: 8px solid transparent;
      border-top: 8px solid transparent;
      top: 6px;
      left: 1px;
    }
  }

  &.bottom {
    top: 38px;
    padding: 5px 10px;
    border-top: 3px solid $alert;
    animation: 1s anim-lightspeed-in-top ease forwards;

    @include tablet {
      top: 40px;
    }

    &:after {
      border-bottom-color: $alert;
      border-bottom-style: solid;
      border-bottom-width: 6px;
      border-right: 8px solid transparent;
      border-left: 8px solid transparent;
      top: -7px;
      left: 50%;
    } 
  }  
}

.thank-you {
  background-color: $white;
  width: 90%;
  margin: 0px 15px 0;
  position: relative;
  padding: 50px 10px 10px 20px;

  @include tablet  {
    padding: 80px 25px 80px 40px;
  }

  &::after {
    content: '';
    position: absolute;
    z-index: -1;
    width: calc(100% + 8px);
    height: 98%;
    bottom: -7px;
    left: -4px;
    background-color: $black;

    @include tablet {
      height: 95%;
      background-color: $black;
      bottom: -7px;
      left: -7px;
      width: calc(100% + 14px);
    }
  }
  
  a {
    color: $accent;
    font-weight: bold;
  }

  ul {
    margin-bottom: 50px;
    display: block;

    li {
      margin-bottom: 10px;
    }
  }
}



form {
  ul {
    display: -ms-flexbox;
    @include flexbox;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -ms-flex-pack: center;
    justify-content: center;
    -ms-flex-line-pack: justify;
    align-content: space-between;
    width: 100%;
  }

  li {
    margin: 0;
    @include flexbox;
    list-style: none;
    position: relative;
    @include flex-direction(row);
  }

  input, select {
    margin: 1px 0px 10px 1px;
    // margin: 0 5px 10px;
    height: 50px;
    display: block;
    flex-shrink: 1;
  }

  label input {
    display: inline-block;
  }



  .half {
    input {
      width: 97%;
    }
  }

  .border {
    border: 2px solid $yellow;
    width: 96.8%;
    height: 50px;
    position: absolute;
    top: -3px;
    left: -3px;
  }

  .borderArea {
    border: 2px solid $yellow;
    width: 98.3%;
    height: 91px;
    position: absolute;
    top: -3px;
    left: -3px;
  }

  .full {
    .border {
      width: 98.5%;
    }
  }
}

// Forms
form {
  &.small-form {
    margin-bottom: 20px;
    text-align: center;
  }

  li {
    margin: 0;
    @include flexbox;
    list-style: none;
    position: relative;
    @include flex-direction(row);
  }

  .non-flex li.l {
    @include mobile {
      width: 60%;
    }
    
    @include mobile-landscape {
      width: auto;
    }  
  }

  ul.non-flex {
    display: block;
    margin: 20px 0 0;
    text-align: left;
    width: 100%;
    position: relative;
  
    .error {
      width: 300px;
      text-align: center;

      &.right {
        @include mobile {
          left: auto;
          right: 10px;
          width: 180px;
        }

        @include mobile-landscape {
          width: 300px;
          right: auto;
          left: calc(50% - 150px);
        }

        @include iphone-x {
          left: auto;
          right: 10px;
          width: 180px;
        }

        @include iphone-x-landscape {
          width: 300px;
          right: auto;
          left: calc(50% - 150px);
        }
      }

      &.bottom {
        top : auto;
        bottom: 20px;
      }
    }

    p {
      text-align: center;
    }
  
    li {
      margin: 0;
    }
  
    .half {
      .error {
        left: 30%;
      }
    }
  }
}


// Sizing

.full {
  width: 100%;

  .error {
    width: 240px;
    left: calc(50% - 120px);
    left: -moz-calc(50% - 120px);
    left: -webkit-calc(50% - 120px);

    @include mobile-landscape {
      width: 320px;
      left: -moz-calc(50% - 160px);
      left: calc(50% - 160px);
      left: -webkit-calc(50% - 160px);
    }

    @include tablet {
      width: 320px;
      left: -moz-calc(50% - 160px);
      left: calc(50% - 160px);
      left: -webkit-calc(50% - 160px);
      right: auto;
    }
  }
}

.half {
  width: 50%;

  .error {
    width: 90%;
    left: calc(50% - 100px);
    left: -moz-calc(50% - 100px);
    left: -webkit-calc(50% - 100px);

    @include mobile {
      width: 90%;
      left: 5%;
    }

    @include tablet {
      width: 320px;
      left: calc(50% - 160px);
      left: -moz-calc(50% - 160px);
      left: -webkit-calc(50% - 160px);
    }
  }
}

li.full-mobile {
  @include mobile {
    width: 100%;
  }

  .error {
    width: 240px;
    left: calc(50% - 120px);
    left: -moz-calc(50% - 120px);
    left: -webkit-calc(50% - 120px);

    @include mobile-landscape {
      width: 320px;
      left: calc(50% - 160px);
    }

    @include tablet {
      width: 320px;
      left: calc(50% - 160px);
      right: auto;
    }
  }
}

li.half-mobile {
  @include mobile {
    width: 50%;
  }

  .error {
    width: 320px;
    left: calc(50% - 160px);

    @include tablet {
      right: auto;
    }
  }
}

.quarter {
  width: 25%;

  .error {
    left: 0;
    width: 95%;
    padding: 5px 5px;
    margin: 0 5px;
  }
}

.third {
  width: 33%;

  .error {
    width: 100px;
    left: 0;
    width: 92%;
    padding: 5px 5px;
    margin: 0 5px;
  }
}
