@import "base/colors";

header {
  background-color: $yellow;
  height: 135px;
  position: fixed;
  width: 100%;
  padding-top: 15px;
  z-index: 10;
  left: 0;
  right: 0;
  top: 0;

  @include tablet {
    background-color: $yellow;
    position: relative;
  }

  // @include iphone-x-landscape {
  //   background-color: $yellow;
  //   height: 135px;
  //   position: fixed;
  //   width: 100%;
  //   padding-top: 15px;
  //   z-index: 10;
  //   left: 0;
  //   right: 0;
  //   top: 0;
  // }

  .left {
    width: 300px;
    position: absolute;
    left: 0;
    padding: 2px 0 0px 22px;

    a {
      color: $black;
      text-transform: uppercase;
      text-decoration: none;
      @include regular;
      font-size: 0.875em;
    }
  }

  .center {
    width: 300px;
    position: absolute;
    text-align: center;
    left: calc(50% - 150px);
    padding-top: 20px;
  }

  .right {
    width: 150px;
    position: absolute;
    right: 95px;
    text-align: right;
    top: -9px;

    a {
      color: $black;
      text-transform: uppercase;
      text-decoration: none;
      @include regular;
      font-size: 0.875em;
    }
  }

  .right-menu {
    width: 95px;
    position: absolute;
    right: 15px;
    text-align: right;

    @include tablet {
      right: 10px;
    }
  }

  .logo {
    a {
      text-decoration: none;
      
    }

    h3 {
      @include regular;
      color: $accent;
      font-size: 1.700em;
      letter-spacing: -1px;

      @include tablet {
        font-size: 1.900em;
      }
    }
  }
}

@include mobile {
  header.scrolled-in {
    position: fixed;
    height: 60px; 

    &::after {
      height: 250px!important;
      bottom: 5px;
      @include divider;
      background-color: $yellow;
      @include mdboxshadow(1);

      @include mobile-landscape {
        bottom: 20px;
      }
    }
  
    .center {
      padding-top: 0;
  
      .logo img {
        width: 120px;
        height: auto;
        -webkit-transition: width .2s;
        -moz-transition: width .2s;
        -ms-transition: width .2s;
        -o-transition: width .2s;
        transition: width .2s;
      }
    }
  
    
  }
}



