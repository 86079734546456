@import "base";
@import "base/easings";
@import 'base/typography';

.address-checker {

  h6 {
    @include text-shadow($yellow);
    text-transform: uppercase;
    margin-bottom: 15px;
  }

  .result {
    width: 100%;
    height: 71px;;
    border: 2px $black solid;
    margin: 0 10px 5px 0px;
    text-align: left;
    background: rgba($accent, 0.5);
    @include transition(background-color 0.4s, color 0.4s, border-color 0.4s);
    @include transform(translateY(0));
    animation: 0.2s anim-lightspeed-in-top ease forwards;
    display: flex;
    justify-content: space-between;
    position: relative;
    cursor: pointer;

    @include tablet {
      height: 104px;
    }

    &:hover {
      @include mdboxshadow(3, $yellow);
      @include transform(translateY(1));
      border-color: $yellow;
    }

    &.selected {
      border-color: $yellow;
    }

    h6 {
      @include bold;
      text-transform: uppercase;
      font-size: 14px;
      @include text-shadow(transparent);
      text-align: left;
      margin: 5px 0;

      @include mobile-xs {
        font-size: 12px;
      }

      @include tablet {
        margin: 20px 0 5px;
        font-size: 14px;
      }

      @include desktop {
        margin: 30px 0 5px;
      }

    }

    img {
      width: 100px;
      height: 67px;

      @include mobile-xs {
        width: 90px;
        height: 67px;
      }
      

      @include tablet {
        height: 100px;
        width: 150px;
      }
    }

    .details {
      flex: 1;
      padding: 0px 7px;

      @include tablet {
        padding: 0px 25px;
      }

      p {
        padding: 0;
        line-height: 1.3em;
        @include regular;
        text-align: left;
        font-size: 0.875em;
        width: 150px;

        @include mobile-xs {
          font-size: 10px;
          width: 105px;
        }

        @include tablet {
          width: auto;
        }
      }
      
    }

    .placeholder {
      img {
        position: absolute;
        top: 5px;
        left: 0;
        

        @include tablet {
          top: 10px;
        }
      }

      .details {
        padding-left: 100px;

        @include tablet {
          padding-left: 150px;
        }

        h6 {
          text-align: center;
          padding-top: 10px;

          @include tablet {
            padding-top: 0;
          }
        }
      }
    }

    .delete {
      cursor: pointer;

      margin-right: 5px;
      width: 25px;
      height: 25px;
      margin-top: 20px;
      font-size: 1.5em;
      line-height: 22px;
      font-weight: bold;
      text-align: center;
      color: $black;
      @include text-shadow($yellow);

      @include tablet {
        margin-right: 20px;
        width: 30px;
        height: 30px;
        line-height: 26px;
        font-size: 3em;
        margin-top: 35px;
      }

      &:hover {
        color: $black;
      }
    }
  }
}