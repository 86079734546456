@import "base/easings";


.rollover-link {
		span:after {
			content: attr(data-active);
			width: 70px;
			text-align: left;
			font-weight: bold;
		}

		&:hover span:after {
			content: attr(data-hover);
			font-weight: normal;
		}
}

.nav-toggle {
	display: block;
	width: 30px;
	position: absolute;
	top: 0px;
	right: 0;
	z-index: 9;
	margin-top: env(safe-area-inset-top);
	right: env(safe-area-inset-left);

	a {
		display: block;
		margin: 0;
		cursor: pointer;
		transition: padding 0.3s $ease-out-cubic;
		background-image: url('../../images/menu.svg');
		background-repeat: no-repeat;
		background-size: 100% auto;
		width: 28px;
		height: 16px;
	}
}



.close-btn {
	position: absolute;
  z-index: 11;
	display: block;
	width: 20px;
	height: 20px;
	padding: 2px;
	padding-bottom: 0;
  box-sizing: content-box;
  overflow: hidden;

	&.dark {
		span:before {
			background: $black;
		}

		span:after {
			background: $black;
		}
	}

	&.light {
		span:before {
			background: $yellow;
		}

		span:after {
			background: $accent;
		}
	}

	> div {
		position: relative;
		width: inherit;
		height: inherit;
		cursor: pointer;

		div {
			position: relative;
			width: inherit;
			height: inherit;
			pointer-events: none;
			@include transform(rotate(-135deg));
		}

	    &:hover {
			span:first-child {
				&:before {
					transition: transform 0.2s $ease-in-out-sine;
					@include transform(translateX(-108%));
				}

				&:after {
					left: 76%;
					width: 56%;
					@include transform(translateX(-150%));
					transition-delay: 0.1s;
				}
			}

			span:last-child {
				&:before {
					@include transform(translateY(-108%))
				}

				&:after {
					top: 76%;
					height: 56%;
					@include transform(translateY(-150%));
					transition-delay: 0.1s;
				}
			}
		}

		.touch & {
			&:hover {
				span:first-child {
					&:before {
						transition: none;
					}
				}
			}
		}
	}


	span {
		$lineWeight: 3px;

		display: block;
		position: absolute;
		width: 250%;
		height: $lineWeight;

		&:before {
			content: "";
			display: block;
			position: absolute;
			background: $accent;
		}

		&:after {
			content: "";
			display: block;
			position: absolute;
			background: $black;
		}

		&:first-child {
			top: 50%;
			left: 0;

			&:before {
				left: -8%;
				width: 56%;
				height: $lineWeight;
				@include transform(translateX(0));
				transition: transform 0.3s $ease-in-out-sine;
			}

			&:after {
				top: 0;
				left: 62%;
				width: 60%;
				height: $lineWeight;
				@include transform(translateX(0));
				transition: transform 0.3s $ease-in-out-sine, width 0.5s $ease-in-out-sine;
			}
		}

		&:last-child {
			top: 0;
			left: 50%;
			width: $lineWeight;
			height: 250%;

			&:before {
				width: $lineWeight;
				height: 56%;
        top: -8%;
        @include transition(transform 0.35s $ease-in-out-sine);
			}

			&:after {
				top: 60%;
				left: 0;
				width: $lineWeight;
				height: 40%;
				transition: transform 0.35s $ease-in-out-sine, height 0.5s $ease-in-out-sine;
			}
		}

	}
}

.btn {
  @include bold;
	line-height: 4.125em;
	margin: 2em 10px 2em 5px;
	width: 200px;
  font-size: 1.000em;
  position: relative;
  text-decoration: none;
  text-transform: uppercase;
  color: $black;
  display: inline-block;
  padding: 0 3rem;
	height: 60px;
  white-space: nowrap;
  text-overflow: ellipsis;
  -moz-appearance: none;
	-webkit-appearance: none;
	background: $yellow;
	position: relative;
	z-index: 10;
  cursor: pointer;
  @include user-select;
	@include mdboxshadow(3, $accent);
	@include transition(background-color 0.4s, color 0.4s, border-color 0.4s);
	overflow: visible;

	@include tablet {
		width: 250px;
	}

	&::before {
		content: '';
		position: absolute;
		top: 3px;
		left: 3px;
		width: 198px;
		height: 58px;
		z-index: -1;
		border: 2px solid $black;
		
		@include tablet {
			width: 248px;
		}
	}
  
	&:hover {
		text-decoration: none
	}

  &:active,
  &:focus {
		background-color: $accent;
		color: $black;
		@include transform(translateY(1px));
		@include transition(background-color 0.4s, border-color 0.4s);
	}

	&.small {
		width: 100px;
		background: $accent;
		height: 35px;
		line-height: 3.125em;
		margin: 0;
		padding: 0 20px;

		&::before {
			width: 98px;
			height: 33px;
			top: 2px;
			left: 2px;
		}
	}
	
	
}
